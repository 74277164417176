import React, { useCallback, useEffect } from 'react'

import { uniqBy } from 'lodash'

import SEO from 'components/shared/seo'
import { useHandleItems } from 'content-queries/cx/handle-items'
import fireEvent from 'context/tracking/events'
import CatalogProducts, { ProductItem } from 'mx/cx/category/catalog-products'
import FilterLayout from 'mx/cx/filter-layout'

type Filters = {
  color: string[]
  category: string[]
  priceRange: string[]
}

const initialFilters: Record<keyof Filters, any[]> = {
  color: [],
  category: [],
  priceRange: [],
}

const HandlesPage = ({ path }: { path?: string }) => {
  const { data: content } = useHandleItems()

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Catalog - Handles',
    })
  }, [])

  const applyFilters = useCallback(
    (activeFilters: Filters | null) => (item: ProductItem) => {
      const flags = []
      if (activeFilters?.category)
        flags.push(
          activeFilters.category.includes(String(item.category?.title)),
        )
      if (activeFilters?.color)
        flags.push(activeFilters.color.includes(String(item.color?.title)))
      if (activeFilters?.priceRange)
        flags.push(activeFilters.priceRange.includes(String(item.priceRange)))

      return flags.every(Boolean)
    },
    [],
  )

  const buildFilterData = useCallback((sortedItems: ProductItem[]): Filters => {
    const filterData = { ...initialFilters }

    sortedItems.forEach((item) => {
      filterData.category.push(item.category)
      filterData.color.push(item.color)
      filterData.priceRange.push(item.priceRange)
    })

    return {
      category: uniqBy(filterData.category, (c) => c.title),
      color: uniqBy(
        filterData.color,
        (c) => (c as unknown as { title: string }).title,
      ),
      priceRange: [...new Set(filterData.priceRange)].sort().filter(Boolean),
    }
  }, [])

  return (
    <FilterLayout<ProductItem, Filters>
      path={path}
      title="Handles"
      applyFilters={applyFilters}
      buildFilterData={buildFilterData}
      content={content}
      initialFilters={initialFilters}
    >
      {({ currentItem, filteredItems, onChangeFilter }) => (
        <>
          <CatalogProducts
            product="handles"
            itemFromHash={currentItem}
            items={filteredItems}
            onChangeFilter={onChangeFilter}
          />
        </>
      )}
    </FilterLayout>
  )
}

export default HandlesPage

export const Head = () => (
  <SEO
    description="Discover our new modern kitchen cabinet handles, from handleless options to recessed channels to modern hardware."
    title="Catalog - Handles"
  />
)
